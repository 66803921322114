import React, { useState } from 'react'
import "./styles.css"
import { motion, AnimatePresence } from 'framer-motion'
import {useHistory} from 'react-router';
import { ReCaptcha } from 'react-recaptcha-google'
import { randomNumber, addDiscount } from '../../utils.js'

const CheckoutModal = ({ config, ordersRef, cartState, setCartState, discount, modalOpen, modalOpenHandler }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [cnpj, setCNPJ] = useState("")
  const [phone, setPhone] = useState("")

  const history = useHistory()

  // This is to verify if the user passed the captcha test
  const [captcha, setCaptcha] = useState(false)

  /* For RECAPTCHA
  *************** */

  function verifyCallback(recaptchaToken) {
    if(recaptchaToken) { setCaptcha(true) }
  }

  function onLoadRecaptcha() {
      if (ReCaptcha.captchaDemo) {
          ReCaptcha.captchaDemo.reset();
      }
  }

  /* End RECAPTCHA functions
  ************************ */

  // This gets the total proce of the cartState multiplied by the quantity of each item.
  function getTotalPrice(cartState) {
    return cartState.reduce((acc, item) => acc + item.price * item.quantity, 0)
  }

  function handleSubmit(e) {
    e.preventDefault();

    // if the user pass in the captcha test
    if(captcha) {

        // Preparing the variables that will be sent to the database
        const page = config.page
        const url = config.url
        const orderProducts = []
        const subtotal = `${config.currency} ${(getTotalPrice(cartState) * 0.01).toFixed(2).replace('.',',')}`
        const total = `${config.currency} ${(addDiscount(getTotalPrice(cartState), discount) * 0.01).toFixed(2).replace('.',',')}`
        const discountApplied = discount ? `${discount}%` : 0
        const date = new Date();
        const orderNum = randomNumber(10000000, 99999999)

        for (let i = 0; i < cartState.length; i++) {
          orderProducts.push(`${cartState[i].sku} - ${cartState[i].name} x ${cartState[i].quantity}`)
        }

        ordersRef.add({
        orderNum: orderNum,
        page: page,
        customerData: {
            name: name,
            email: email,
            company: company,
            cnpj: cnpj,
            phone: phone
        },
        products: orderProducts,
        subtotal: subtotal,
        discount: discountApplied,
        total: total,
        date: date,
        language: config.language,
        url: url,
        })

        history.push('/thank-you', {
          products: orderProducts,
          total: total,
          url: config.url,
          color: config.color.button,
          title: config.texts.thankYou.title,
          subTitle: config.texts.thankYou.subTitle,
          buttonText: config.texts.thankYou.button,
        });
    }
    // If the captcha has not passed or the user has not selected it
    else {
        alert(config.texts.form.alert)
    }
  }

  return (
    <AnimatePresence exitBeforeEnter>
    { modalOpen && (
      <motion.div className={"modal-wrapper"}
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '100vw' }}
      >
        <div className="modal-close"><button onClick={modalOpenHandler} >x</button></div>
        <div className="modal-title"><h2>{config.texts.form.title}</h2></div>
        <div className="modal-form">
          <form onSubmit={(e) => handleSubmit(e)}>
            <label>
              {config.texts.form.name}
              <input required type="text" name="name" id="form-name" value={name} onChange={(e) => setName(e.target.value)}/>
            </label>
            <label>
              {config.texts.form.email}
              <input required type="text" name="email" id="form-email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            </label>
            <label>
              {config.texts.form.company}
              <input required type="text" name="company" id="form-company" value={company} onChange={(e) => setCompany(e.target.value)}/>
            </label>
            <label>
              {config.texts.form.code}
              <input required type="text" name="cnpj" id="form-cnpj" value={cnpj} onChange={(e) => setCNPJ(e.target.value)}/>
            </label>
            <label>
              {config.texts.form.phone}
              <input required type="text" name="phone" id="form-phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </label>
            <ReCaptcha
              className="reCaptcha"
              ref={(el) => {ReCaptcha.captchaDemo = el;}}
              size="normal"
              render="explicit"
              sitekey="6LeW4_UUAAAAAKKFbtvmIfVPbELjRzgT_4EetkGW"
              onloadCallback={onLoadRecaptcha}
              verifyCallback={verifyCallback}
          />
          <p>&nbsp;</p>
            <input style={{backgroundColor: config.color.button}} type="submit" value={config.texts.form.button} className="modal-button"/>
          </form>
        </div>
      </motion.div> )}
    </AnimatePresence>
  )
}

export default CheckoutModal
