import React from 'react'
import { motion } from 'framer-motion'
import "./styles.css"

const Backdrop = () => {

  return (
    <motion.div className="backdrop" initial={{opacity:0}} animate={{opacity: 1}} ></motion.div>
  )
}

export default Backdrop
