import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ReactGA from 'react-ga';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ThankYou from './pages/thank-you';

// Test Page
import Test from './pages/Test';

// Hot Sale
// import EC_01 from './pages/HotSale/EC/EC_01'

// Cyber Week
// import CW_ECR_Argentina from './pages/CyberWeek/EC/Argentina'
// import CW_ECR_Chile from './pages/CyberWeek/EC/Chile'
// import CW_ECR_Chile2 from './pages/CyberWeek/EC/Chile2'
// import CW_ECR_Argentina01 from './pages/CyberWeek/EC/Argentina'
// import CW_ECR_Peru01 from './pages/CyberWeek/EC/Peru'
// import CW_ECR_Colombia01 from './pages/CyberWeek/EC/Colombia'
//
// import CW_EM_Handler from './pages/CyberWeek/EM/Handler'
// import CW_EV_Braskem from './pages/CyberWeek/EV/Braskem'
// import CW_EM_Embacaps from './pages/CyberWeek/EM/Embacaps'
// import CW_EM_Tovani from './pages/CyberWeek/EM/Tovani'

ReactGA.initialize('UA-110138129-38');

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/test" component={Test} />
      <Route exact path="/thank-you" component={ThankYou} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
