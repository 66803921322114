import React from 'react'
import "./styles.css"

const MainBanner = (props) => {
  return (
    <div className="main-banner">
      <img src={props.img} alt={props.alt} />
    </div>
  )
}

export default MainBanner
