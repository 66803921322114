import React, { useState, useContext } from 'react'
import { motion } from 'framer-motion'
import parse from 'html-react-parser'
// import products from "../../data/productsData"
import "./styles.css"
import { TabContext } from '../../contexts/TabContext'

import Cart from "../cart"
import Tabs from "../tabs"
import AddedToCartPopUp from "../addedToCartPopUp"

const Products = ({config, data, ordersRef, tabData}) => {
  const products = data

  /* THIS IS THE MAIN STATE OF THE APPLICATION
  HERE WE TRACK THE CURRENT STATE OF THE CART
  IT`S PASSED TO CART AS PROPS CALLED cartState
  ****************************************** */
  const [cart, setCart] = useState([])
  const [addedToCartPopUp, setAddedToCartPopUp] = useState(false)
  const {tab} = useContext(TabContext)

  // This function gives quantites to the items
  function itemsWithQuantities(items) {
    return items.reduce((acc, item) => {
      const found = acc.find(_item => _item.sku === item.sku)
      // if the item is already in the cart (it has the property quantity), add +1
      if (found) {
        //
      }
      // If not yet, add the property quantity to the item, and set it to 1.
      else {
        acc.push({
          quantity: 1,
          ...item,
        })
      }
      return acc
    }, [])
  }

  // This adds items to the cart
  function addToCart (product) {
    setCart(prevState => [...prevState, product])
    if(!verifyIfInTheCart(product)) { setAddedToCartPopUp(true) }
  }

  // Verify if the product is already in the cart
  function verifyIfInTheCart(product) {
    for(let item of cart) {
      if (product.sku === item.sku) {
        return true
      }
    }
    return false
  }

  // This display the price per unit (FOR EXAMPLE   PRICE / KG)
  function displayPricePerUnit (product) {
    return `${product.currency} ${((product.price * 0.01) / product.pack).toFixed(2).replace('.',',')} / ${product.metric}`
  }

  function displayRefPricePerUnit (product) {
    return `${product.refCurrency} ${((product.refPrice * 0.01) / product.pack).toFixed(2).replace('.',',')} / ${product.metric}`
  }

  // This displays the full price of the product, with the cents
  function displayFullPrice (product) {
    return `${product.currency} ${((product.price * 0.01)).toFixed(2).replace('.',',')}`
  }

  const validade = function(product) {
    if(product.expDate) {
      return <p className="product-date">Data de validade: {product.expDate}</p>
    }
    else {
      return null;
    }
  }

  let filteredProducts = config.tabs ? products.filter(product => Number(product.tab) === tab) : products;

  const productsRender = filteredProducts.map(product => (
      <motion.div className="product openSans" key={product.sku}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        >
        <h3 className="product-title">{product.name}</h3>
        <div className="product-description">{parse(product.description)}</div>
        <div className="product-image-container">
          {product.image && <img src={product.image} alt={product.name} className="product-image"></img>}
        </div>
        {product.descuento && <p className="product-discount">{config.texts.productSale} {product.descuento}</p>}
        {validade(product)}
        {product.refPrice && <h4 className={config.lineOnRefPrice? "product-refPrice line": "product-refPrice"}>{displayRefPricePerUnit(product)}</h4>}
        <h4 className="product-price">{displayPricePerUnit(product)}</h4>
        {/* LEAVING WITHOUT PACKAGE SIZE FOR HOTSALES
          <p className="product-pack">{`${config.texts.package}: ${product.pack.replace('.',',')} ${product.metric}`}</p>
        */}
        <div className="product-documents">
          {product.documents.split(',').map(doc => (
            <p key={doc} ><a href={`/documents/${doc}.txt`} target="_blank" rel="noopener noreferrer" >{doc}</a></p>
          ))}
        </div>
        <motion.button style={{backgroundColor: config.color.button}} className="product-button" onClick={() => addToCart(product)}
          whileHover={{ scale: 1.05 }}
          > {config.texts.productButton}
        </motion.button>
      </motion.div>
    ))

  return (
    <>
      <Tabs config={config}/>
      { tabData && <div className="intro-text">
        <p>{tabData.intro}</p>
      </div> }
      <div className="products-wrapper">
        {productsRender}
      </div>
      <Cart config={config} ordersRef={ordersRef} cartState={itemsWithQuantities(cart)} setCartState={setCart} displayPricePerUnit={displayPricePerUnit} displayFullPrice={displayFullPrice} />
      <AddedToCartPopUp config={config} cartState={itemsWithQuantities(cart)} addedToCartPopUp={addedToCartPopUp} setAddedToCartPopUp={setAddedToCartPopUp} />
    </>
  )
}

export default Products
