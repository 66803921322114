import firebase from "firebase/app"
import "firebase/firestore"

const config = {
  apiKey: "AIzaSyCpL7wTw551kFGBmJuxOzQ-ldCuub3O9KQ",
  authDomain: "basf-store.firebaseapp.com",
  databaseURL: "https://basf-store.firebaseio.com",
  projectId: "basf-store",
  storageBucket: "basf-store.appspot.com",
  messagingSenderId: "268338494380",
  appId: "1:268338494380:web:599a34afff2e5dd9159809"
}

firebase.initializeApp(config)

const db = firebase.firestore()

const ordersRef = db.collection("orders")

export { ordersRef }
