import React, { useContext } from 'react'
import './styles.css'
import { motion } from 'framer-motion'
import { TabContext } from '../../contexts/TabContext'

const Tabs = ({config}) => {
  const { setTab } = useContext(TabContext)

  const pageTabs = config.tabs || [];
  function changeTab(num) {
    setTab(num);
  }

  const renderTabs = pageTabs.map((tab, index) => (
        <div key={index}><motion.button style={{backgroundColor: tab.tabColor}} whileHover={{ boxShadow: "0px 0px 12px rgb(0,0,0)" }} onClick={() => changeTab(index + 1)}>{tab.tabName}</motion.button></div>
      )
    )

  return (
    <div className="tabs-wrapper">
      {renderTabs}
    </div>
  )
}

export default Tabs
