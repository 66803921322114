import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import "./styles.css"
import CheckoutModal from "../checkout-modal"

//utils
import { addDot, addDiscount } from '../../utils.js'

const Cart = ({ data, config, ordersRef, cartState, setCartState, displayPricePerUnit, displayFullPrice }) => {
  const [cartOpen, setCartOpen] = useState(false) // This is a state to check if the cart if OPEN or CLOSE
  const [modalOpen, setModalOpen] = useState(false) // This is a state to check if the MODAL/CHECKOU is OPEN or CLOSE
  const [cupon, setCupon] = useState("") // This is a state to save the cupon typed by the user
  const [cuponApplied, setCuponApplied] = useState(false) // Sets if the cupon was sucessfully applied
  const [discount, setDiscount] = useState(false)

  /* When clicking on the cart icon (on the right side of the screen)
  * toggle the cartOpen state */
  function cartOpenHandler() {
    setCartOpen(!cartOpen)
  }

  function modalOpenHandler() {
    // Verica se tem itens no carrinho
    if(cartState.length < 1) {
      alert(config.texts.alert)
    }
    // Se tiver, abre o pop up de checkout
    else {
      setModalOpen(!modalOpen)
    }
  }

  // This gets the total proce of the cartState multiplied by the quantity of each item.
  function getTotalPrice(cartState) {
    return cartState.reduce((acc, item) => acc + item.price * item.quantity, 0)
  }

  //Verify if cupon exists in the config
  function verifyCupon(cupon) {
    for (let key of Object.keys(config.cupons)) {
      if(cupon === key) {
        setDiscount(config.cupons[cupon])
        return true
      }
    }
    return false;
  }
  // Adds dicount based on coupon
  function applyDiscount() {
    if(verifyCupon(cupon)) {
      setCuponApplied(true);
    }
    else {alert('Cupom Inválido')}
  }

  // This removes items from the cart
  function removeFromCart (product) {
    setCartState(prevState => [...prevState].filter(item => item.sku !== product.sku))
  }

  // This updates the quantity of each item when changed inside the input
  function updateQuantity(e, index) {
    e.persist(); // Necessary so React does not return null after the first interaction

    setCartState(prevState => {
      const newState = [...prevState] //newState is a copy of prevState
      newState[index].quantity = Number(e.target.value)
      // Adding discount price if it has the necessary quantity
      // applyDiscount(newState, index)
      return newState
    })
  }

  // Verify if the total ammount is highter than the minimun to apply discount, if it is, apply the discount, else, remove the discount
  useEffect(() => {
    if(config.discountRule){
      if(getTotalPrice(cartState) > config.discountRule.min){
        setDiscount(config.discountRule.discount)
        setCuponApplied(true)
      }
      else {
        setDiscount(false)
        setCuponApplied(false)
      }
    }
  }, [cartState, config.discountRule])

  let formatedTotal = (getTotalPrice(cartState) * 0.01).toFixed(2).replace('.',',')
  let discountedTotal = (addDiscount(getTotalPrice(cartState), discount) * 0.01).toFixed(2).replace('.',',')
  let formatedDiscount = (getTotalPrice(cartState) * discount / 100 * 0.01).toFixed(2).replace('.',',')

  return (
    <>
      <CheckoutModal config={config} ordersRef={ordersRef} cartState={cartState} setCartState={setCartState} modalOpen={modalOpen} modalOpenHandler={modalOpenHandler} discount={discount} />
      <div className={cartOpen? "cart-open": "cart-close"}>
        <div className="cart-icon">
          <motion.button whileHover={{ boxShadow: "0px 0px 12px rgb(0,0,0)" }} style={{backgroundColor: config.color.page}} href="#" id="cart-open-link" onClick={cartOpenHandler} >
            <img src="/images/truck.png" alt="Caminhao de compras" width="40px" />
            <p>{cartState.length}</p>
          </motion.button>
        </div>
        <div className={cartOpen? "cart-wrapper": "hide"}>
          {/* CART HEADER *
          ************** */}
          <div className="cart-close-btn"><button onClick={cartOpenHandler}>x</button></div>
          <div className="cart-title"><h3>{config.texts.cartTitle}</h3></div>

          {/* CART BODY *
          ************ */}
          <div className={config.cupons ? "cart-products cart-products-cupons": "cart-products"}>
            {cartState.map((product, index) => (
              <div className="cart-item" key={index}>
                <div className="cart-item-name">{product.name}</div>
                <div className="cart-item-quantity">{config.texts.cartQuantity}: <input type="number" defaultValue={cartState[index].quantity} min={1} onChange={(e) => updateQuantity(e, index)}></input></div>
                <div className="cart-item-price">{displayFullPrice(product)}</div>
                <div className="remove-item"><button onClick={() => removeFromCart(product)}>x</button></div>
              </div>
            ))}
          </div>

          {/* CART FOOTER *
          *************** */}
          <div className="cart-footer">
            <div className="cart-subtotal">
              <div className="cart-subtotal-text">Subtotal</div>
              <div className="cart-subtotal-value">{`${config.currency} ${addDot(formatedTotal)}`}</div>
            </div>
            {discount && <div className="cart-discount-wrapper">
              {!cuponApplied ? <div className="cart-discount">
                <button className="discount-button" onClick={applyDiscount}>Adicionar cupom de desconto</button>
                <input type="text" id="discount-code" name="discount-co de" onChange={(e) => setCupon(e.target.value)}></input>
              </div> :
              <div className="cart-discount-applied">
                <div className="discount-total-text">Desconto</div>
                <div className="discount-total-value">{`${config.currency} ${addDot(formatedDiscount)}`}</div>
              </div> }
              <div className="cart-total">
                <div className="cart-total-text">Total</div>
                <div className="cart-total-value">{`${config.currency} ${addDot(discountedTotal)}`}</div>
              </div>
            </div>}
            <div className="cart-disclaimer"><p>{config.texts.cartDisclaimer}</p></div>
            <div className="cart-purchase-button"><motion.button whileHover={{ boxShadow: "0px 0px 12px rgb(0,0,0)" }} style={{backgroundColor: config.color.button}} onClick={modalOpenHandler} >{config.texts.cartButton}</motion.button></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cart
