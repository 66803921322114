import React from "react"
import '../../App.css';
import { ordersRef } from "../../firebase"
import ReactGA from 'react-ga';
import { loadReCaptcha } from 'react-recaptcha-google'

import texts from "../../texts"
import textsHotSale from "../../texts/hotsale"

import Header from "../../components/header"
import MainBanner from "../../components/main-banner"
import Products from "../../components/products"
import Footer from "../../components/footer"

const config = {
  page: "Test",
  url:"/test",
  tabs: ['Test1', 'Test2', 'Test3'],
  color: {
    page: "#c10b25",
    button: "rgb(243, 149, 0)",
  },
  currency:"ARS",
  language:"es",
  contact: {
    name: "Test",
    email: "Test@test.com",
    phone: "+55 (11) 123456789"
  },
  // discountRule: {
  //   min: 254000,
  //   discount: 5,
  // },
  // cupons: {
  //   "10Porcento": 10,
  //   "20Porcento": 20,
  // }
}
config.texts = texts[config.language]


// Read My Google SpreadSheet and send the data as props to Products
class EC_01 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    loadReCaptcha();
    fetch("https://sheetdb.io/api/v1/kfhhm8jx590rl") // This is the Sheetsu API Url
      .then( (response) => {
        return response.json()
      }).then( (json) => {
        this.setState({data: json}); // get the response from the call and save it to the data state.
      });
  }

  render() {
    return (
      <div className="App">
        <Header color={config.color.page} contact={config.contact}/>
        <MainBanner img="https://promo.basf.com/campaign/HotSales2.jpg" alt="Banner Care Creations"/>
        <h1 className="center">{config.texts.pageTitle}</h1>
        <h2 className="center">Aprovechá la semana de descuentos!</h2>
        <Products config={config} ordersRef={ordersRef} data={this.state.data}/>
        <Footer color={config.color.page}>{textsHotSale.disclaimer}</Footer>
      </div>
    );
  }
}

export default EC_01
