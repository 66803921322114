export const addDot = (str) => {
  const arrStr =  str.split(',')
  const left = arrStr[0]
  const arrleft = left.split('')
  const right = arrStr[1]

  if(arrleft.length === 4) {
    arrleft[0] += '.'
  }
  else if(arrleft.length === 5) {
    arrleft[1] += '.'
  }
  else if(arrleft.length === 6) {
    arrleft[2] += '.'
  }
  else if(arrleft.length === 7) {
    arrleft[0] += '.'
    arrleft[3] += '.'
  }
  else if(arrleft.length === 8) {
    arrleft[1] += '.'
    arrleft[4] += '.'
  }
  else if(arrleft.length === 9) {
    arrleft[2] += '.'
    arrleft[5] += '.'
  }
  else if(arrleft.length === 10) {
    arrleft[0] += '.'
    arrleft[3] += '.'
    arrleft[6] += '.'
  }

  return arrleft.join('') + ',' + right
}

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

export const addDiscount = (value, discount) => {
  return value - (value * discount / 100)
}
