import React from 'react'
import { motion } from 'framer-motion'
import "./styles.css"

import Backdrop from '../backdrop'

const AddedToCartPopUp = ({addedToCartPopUp, setAddedToCartPopUp, cartState, config}) => {

  // CLose the Pop Up
  function closePopUp() {
    setAddedToCartPopUp(false)
  }

  return (
    <>
      {addedToCartPopUp &&
      <>
        <motion.div className="addedToCart-wrapper" initial={{opacity: 0}} animate={{opacity: 1}} >
          <h2 className="addedToCart-title">{config.texts.productPopUp.title}</h2>
          <button style={{backgroundColor: config.color.button}} onClick={closePopUp}>{config.texts.productPopUp.button}</button>
        </motion.div>
        <Backdrop />
      </>}
    </>
  )
}

export default AddedToCartPopUp
