import React, { useEffect } from 'react'
import "./styles.css"
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga';

ReactGA.pageview("/thank-you");

const ThankYou = (props) => {

  useEffect( () => {
      ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <div>
      <motion.div initial={{ x: -1000 }} animate={{ x: 0}} className="thank-you-wrapper">
        <h2>{props.location.state.title}</h2>
        <h4>{props.location.state.subTitle}</h4>
        <Link to={props.location.state.url}><button style={{backgroundColor: props.location.state.color}}>{props.location.state.buttonText}</button></Link>
      </motion.div>
    </div>
  )
}

export default ThankYou
