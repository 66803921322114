import React from 'react'

import "./styles.css"

const Header = (props) => {
  return (
    <header>
      <div style={{backgroundColor: props.color}} className="wrapper">
        <div className="header-logos">
        <img src="/images/logo_basf.png" alt="Logo BASF" />
        { props.img &&
          <div className="second-logo">
            <p>|</p>
            <img src={props.img} alt="logo do parceiro" />
          </div>
        }
        </div>
        {props.contact && <div className="contact-wrapper">
          <p className="contact-name">{props.contact.name}</p>
          <p className="contact-email">{props.contact.email}</p>
          <p className="contact-phone">{props.contact.phone}</p>
        </div>}
      </div>
    </header>
  )
}

export default Header
