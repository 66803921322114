import React from 'react'
import "./styles.css"

const Footer = (props) => {
  return (
    <div style={{ backgroundColor: props.color}} className="footer-wrapper">
      <p>{props.children}</p>
    </div>
  )
}

export default Footer
